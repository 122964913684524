<template>
  <div>
    <div class="row">
      <div class="col-sm-12">
        <div class="card">
          <div class="card-header">
            <div class="row">
              <div class="col-md-4 d-flex justify-content-between align-items-center">
                <div class="header-title me-5">
                  <h4 class="card-title">Пользователи</h4>
                </div>
<!--                <button-->
<!--                  type="button"-->
<!--                  class="btn btn-primary"-->
<!--                  data-bs-toggle="modal"-->
<!--                  data-bs-target="#edit"-->
<!--                  @click="showModal(true)"-->
<!--                >-->
<!--                  Добавить-->
<!--                </button>-->
              </div>
              <form class="col-md-4 offset-md-4 d-flex align-items-center" @submit.prevent="searchUser">
                <div class="form-group input-group mb-0">
                  <input type="search" class="form-control" placeholder="99899 или AB 02921231" name="searchQuery">
                  <button type="submit" class="input-group-text" id="basic-addon1">
                    <svg width="18" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="11.7669" cy="11.7666" r="8.98856" stroke="currentColor" stroke-width="1.5"
                                stroke-linecap="round" stroke-linejoin="round"></circle>
                        <path d="M18.0186 18.4851L21.5426 22" stroke="currentColor" stroke-width="1.5"
                              stroke-linecap="round" stroke-linejoin="round"></path>
                    </svg>
                  </button>
                </div>
              </form>
            </div>
          </div>
          <div class="card-body px-0">

            <Alert v-if="users === null" variant="warning">Данные отсуствует</Alert>

            <div v-else class="table-responsive">
              <table id="user-list-table" class="table" role="grid" data-toggle="data-table">
                <thead>
                <tr class="ligth">
                  <th v-for="(header, index) in tableHeaders" :key="index">{{ header }}</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(user, index) in users" :key="index">
                  <td>{{ user.first_name }} {{ user.last_name }}</td>
                  <td>{{ user.passport }}</td>
                  <td>{{ user.address }}</td>
                  <td>{{ user.phone }}</td>
                  <td>
                    <a href="#"
                       v-if="user.images.length"
                       type="button"
                       class="text-decoration-underline"
                       data-bs-toggle="modal"
                       data-bs-target="#edit"
                       @click="showModal(false, user.id)"
                    >
                      Открыть
                    </a>
                    <a href="#"
                       v-else
                       type="button"
                       class="text-decoration-underline"
                       data-bs-toggle="modal"
                       data-bs-target="#edit"
                       @click="showModal(true, user.id)"
                    >
                      Добавить
                    </a>
                  </td>
                  <td>{{ user.created_at }}</td>
                  <td>
                    <router-link :to="{ name: 'default.orders', query: { id: user.id } }" class="text-decoration-underline">Перейти</router-link>
                  </td>
                  <td class="text-end">
                    <div class="dropdown">
                      <a class="btn btn-primary dropdown-toggle" href="#" role="button" id="dropdownMenuLink"
                         data-bs-toggle="dropdown" aria-expanded="false">
                        Опции
                      </a>
                      <ul class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                        <li>
                          <a class="dropdown-item"
                             href="#"
                             data-bs-toggle="modal"
                             data-bs-target="#edit-user"
                             @click.prevent="onSelectUser(user.id)"
                          >
                            Редактировать
                          </a>
                        </li>
<!--                        <li>-->
<!--                          <button class="dropdown-item btn-link text-danger" @click="deleteCategory(category.id)">-->
<!--                            Удалить-->
<!--                          </button>-->
<!--                        </li>-->
                      </ul>
                    </div>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div v-if="pagination?.total > 10" class="card-footer d-flex justify-content-center">
            <div class="bd-content">
              <CustomPagination
                :total-pages="pagination?.total_pages || 1"
                :total="pagination?.total || 1"
                :per-page="10"
                :current-page="currentPage"
                @pagechanged="onPageChange"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <modal
      ref="ordersModal"
      mainClass="fade"
      :tabindex="-1"
      id="edit"
      ariaLabelled="addModalLabel"
      :ariaHidden="true"
      dialog-class="modal-lg modal-dialog-scrollable"
    >
      <model-header :dismissable="true">Пользователь {{ user?.id }}</model-header>

      <model-body class="px-5">
        <div>
          <template v-if="!isNew">
            <img
              v-for="image in user?.images"
              :key="image.id"
              class="img-fluid me-2"
              width="200"
              :src="image.path"
              :alt="image.name"
            >
          </template>
          <div v-else class="d-flex align-items-center justify-content-center my-4">
            <h2>Нет фото</h2>
          </div>
        </div>

        <!-- <div class="row mt-4">
          <div class="col-md-9">
            <button
              type="button"
              :class="['btn', isNew ? 'btn-secondary' : 'btn-outline-primary']"
              :disabled="isNew"
              @click="deletePhoto"
            >
              Удалить
            </button>
          </div>

          <div class="col-md-3">
            <button
              v-if="isNew"
              type="button"
              :class="['btn w-100', isNew ? 'btn-secondary' : 'btn-outline-primary']"
              disabled
            >
              Скачать
            </button>
            <a
              v-else
              href="#"
              :download="user?.image"
              class="btn w-100 btn-primary"
            >
              Скачать
            </a>
          </div>
        </div> -->

        <Form v-slot="{ meta }" class="row mt-4" @submit="uploadPhoto">
          <div class="col-md-6">
            <label for="passport_photo" class="form-label">
              {{ isNew ? 'Загрузить фото (Лицевавя сторона)' : 'Заменить фото (Лицевавя сторона)' }}
            </label>
            <Field id="passport_photo" type="file" class="form-control" name="images[passport_fornt_page]" rules="required" />
            <ErrorMessage name="images[passport_fornt_page]" class="text-danger" />
          </div>

          <div class="col-md-6">
            <label for="passport_back_photo" class="form-label">
              {{ isNew ? 'Загрузить фото (Задняя сторона)' : 'Заменить фото (Задняя сторона)' }}
            </label>
            <Field id="passport_back_photo" type="file" class="form-control" name="images[passport_back_page]" rules="required" />
            <ErrorMessage name="images[passport_back_page]" class="text-danger" />
          </div>

          <div class="col-12 text-center mt-4">
            <button type="submit" class="btn btn-primary" :disabled="!(meta.valid && meta.dirty)">
              {{ isNew ? 'Добавить' : 'Заменить' }}
            </button>
          </div>
        </Form>
      </model-body>
    </modal>

    <modal
      ref="ordersModal"
      mainClass="fade"
      :tabindex="-1"
      id="edit-user"
      ariaLabelled="addModalLabel"
      :ariaHidden="true"
      dialog-class="modal-lg modal-dialog-scrollable"
    >
      <model-header :dismissable="true">Пользователь {{ user?.id }}</model-header>

      <model-body class="px-5">

        <Form v-if="user !== null" v-slot="{ meta }" class="row mt-4" @submit="onEditUser">
          <input type="hidden" :value="user.id" name="user_id">

          <div class="col-md-6">
            <label class="form-label">Имя</label>
            <Field v-model="user.first_name" type="text" class="form-control" name="last_name" rules="required" />
            <ErrorMessage name="last_name" class="text-danger" />
          </div>

          <div class="col-md-6">
            <label class="form-label">Фамилия</label>
            <Field v-model="user.last_name" type="text" class="form-control" name="first_name" rules="required" />
            <ErrorMessage name="first_name" class="text-danger" />
          </div>

<!--          <div class="col-md-6">-->
<!--            <label class="form-label">Паспорт</label>-->
<!--            <Field v-model="user.passport" type="text" class="form-control" name="passport_id" rules="required" />-->
<!--            <ErrorMessage name="passport_id" class="text-danger" />-->
<!--          </div>-->

          <div class="col-md-12 mt-4">
            <label class="form-label">Адрес доставки</label>
            <Field v-model="user.address" size="4" type="text" class="form-control" name="address" rules="required" />
            <ErrorMessage name="address" class="text-danger" />
          </div>

          <div class="col-12 text-center mt-4">
            <button type="submit" class="btn btn-primary" :disabled="!(meta.valid && meta.dirty)">
              Сохранить
            </button>
          </div>
        </Form>
      </model-body>
    </modal>
  </div>
</template>

<script>
import modal from '@/components/bootstrap/modal/modal'
import modelHeader from '@/components/bootstrap/modal/model-header'
import modelBody from '@/components/bootstrap/modal/model-body'

import CustomPagination from '@/components/custom/pagination/CustomPagination'
import Alert from '@/components/bootstrap/Alert/alert'
import { getUsers, photo, updateUser } from '@/services/user.service'
import { Form, Field, ErrorMessage } from 'vee-validate'
import { useToast } from 'vue-toastification'

export default {
  name: 'UserIndex',
  data: () => ({
    currentPage: 1,
    users: null,
    user: null,
    pagination: null,
    isNew: false,
    tableHeaders: [
      'ФИО',
      'Паспорт',
      'Адрес доставки',
      'Телефон',
      'Фото паспорта',
      'Дата регистрации',
      'Заказы',
      'Действия'
    ]
  }),
  components: {
    modal,
    modelBody,
    modelHeader,
    Form,
    Field,
    ErrorMessage,
    CustomPagination,
    Alert
  },
  methods: {
    onPageChange (page) {
      this.currentPage = page
      this.fetchUsers({ page })
    },
    onSelectUser (userId) {
      this.user = null
      this.user = this.users.find(user => user.id === userId)
    },
    showModal (hasShowModal = false, id) {
      this.user = null
      this.isNew = hasShowModal
      this.user = this.users.find(user => user.id === id)
    },
    searchUser (e) {
      const [input] = e.target

      this.fetchUsers({ searchQuery: input.value })
    },
    async fetchUsers (params) {
      try {
        const usersResponse = await getUsers({ search: params?.searchQuery, page: params?.page })

        this.users = usersResponse.data.data
        this.pagination = usersResponse.data.pagination
      } catch (e) {
        console.error(e.response)
      }
    },
    async uploadPhoto (values) {
      const formData = new FormData()

      Object.entries(values).forEach(([name, value]) => {
        formData.append(name, value)
      })

      await photo.upload(this.user?.id, formData)

      window.location.reload()
    },
    async onEditUser (values) {
      const formData = new FormData()

      formData.append('user_id', this.user.id)
      Object.entries(values).forEach(([name, value]) => {
        formData.append(name, String(value))
      })

      try {
        await updateUser(formData)
        window.location.reload()
      } catch (e) {
        useToast().error(e.response.data.error[0].text)
      }
    },
    async downloadPhoto () {
      await photo.download(this.user?.id)
    },
    async deletePhoto () {
      await photo.remove(this.user?.id, this.user?.image.id)
    }
  },
  async created () {
    await this.fetchUsers()
  }
}
</script>

import ApiClient from '@/api'
import { User } from '@/models'
import axios from '@/plugins/axios'

const api = new ApiClient(User.modelName)

export const getUsers = async (params) => {
  const { data: usersResponse } = await api.getAll(params)

  return usersResponse
}

export const getUserByPhone = async (phone) => {
  return await axios.get('/admin/users/phone', { params: { phone } })
}

export const updateUser = async (formData) => {
  const { data: user } = await api.update(formData)
  return user
}

const upload = async (id, formData) => {
  return await axios.post(`/admin/users/${id}/images`, formData)
}

const download = async (id) => {
  return await axios.post(`/admin/users/${id}/images`)
}

const remove = async (id, imageId) => {
  return await axios.post(`/admin/users/${id}/images/${imageId}`)
}

export const photo = {
  upload,
  download,
  remove
}
